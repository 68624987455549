var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            { staticClass: "mt-5 mb-5", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "auto", md: "2" } }, [
                _c(
                  "h1",
                  {
                    staticClass: "mb-0 text-primary",
                    staticStyle: { "font-size": "22px", "font-weight": "500" },
                  },
                  [_vm._v(" Reminders ")]
                ),
              ]),
              _c(
                "b-col",
                {
                  staticClass: "tw-items-center tw-flex",
                  class: ["patient-profile", "treatments"].includes(_vm.page)
                    ? "tw-justify-end"
                    : "tw-justify-between",
                  attrs: { cols: "12", md: "10" },
                },
                [
                  !["patient-profile", "treatments"].includes(_vm.page)
                    ? _c(
                        "div",
                        { staticClass: "tw-pt-6 md:tw-pt-0 tw-flex-1 tw-flex" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-muted mr-4",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  _vm.selectedTab = "OVERVIEW"
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.selectedTab === "OVERVIEW"
                                      ? "text-primary"
                                      : "",
                                },
                                [_vm._v(" Overview ")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "status-pill",
                                  class:
                                    _vm.selectedTab === "OVERVIEW"
                                      ? "pill-dark-cyan"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.pastDueList.length +
                                          _vm.todayList.length +
                                          _vm.thisWeekList.length
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-muted mr-4",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  _vm.selectedTab = "ALL"
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.selectedTab === "ALL"
                                      ? "text-primary"
                                      : "",
                                },
                                [_vm._v(" All ")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "status-pill",
                                  class:
                                    _vm.selectedTab === "ALL"
                                      ? "pill-dark-cyan"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.reminderList.length) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.currentDoctor.id
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "tw-absolute tw-top-[calc(-100%/2-10px)] tw-right-0 md:tw-relative md:tw-top-auto",
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { width: "190px" },
                              attrs: {
                                pill: "",
                                block: "",
                                variant:
                                  _vm.page === "patient-profile"
                                    ? "outline-primary"
                                    : "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show("setReminder")
                                },
                              },
                            },
                            [_vm._v(" Set Reminder ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        [
          _vm.selectedTab === "OVERVIEW" &&
          !["patient-profile", "treatments"].includes(_vm.page)
            ? _c("div", [
                _c(
                  "ul",
                  {
                    staticClass:
                      "list-unstyled xl:tw-grid-cols-3 tw-space-x-4 tw-hidden xl:tw-grid",
                  },
                  [
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-py-3 tw-capitalize tw-border tw-border-solid tw-border-primary tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-flex tw-justify-between tw-relative",
                        },
                        [
                          _vm._v(" Past Due "),
                          _c("span", { staticClass: "status-pill" }, [
                            _vm._v(_vm._s(_vm.pastDueList.length) + " "),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card-reminders-list" },
                        _vm._l(_vm.pastDueList, function (reminder) {
                          return _c("card-reminder", {
                            key: reminder.id,
                            attrs: { reminder: reminder },
                            on: {
                              reschedule: _vm.onReschedule,
                              snooze: _vm.onSnooze,
                              completed: _vm.onComplete,
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-py-3 tw-capitalize tw-border tw-border-solid tw-border-primary tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-flex tw-justify-between tw-relative",
                        },
                        [
                          _vm._v(" Today "),
                          _c("span", { staticClass: "status-pill" }, [
                            _vm._v(_vm._s(_vm.todayList.length)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card-reminders-list" },
                        _vm._l(_vm.todayList, function (reminder) {
                          return _c("card-reminder", {
                            key: reminder.id,
                            attrs: { reminder: reminder },
                            on: {
                              reschedule: _vm.onReschedule,
                              snooze: _vm.onSnooze,
                              completed: _vm.onComplete,
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-py-3 tw-capitalize tw-border tw-border-solid tw-border-primary tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-flex tw-justify-between tw-relative",
                        },
                        [
                          _vm._v(" This Week "),
                          _c("span", { staticClass: "status-pill" }, [
                            _vm._v(_vm._s(_vm.thisWeekList.length)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card-reminders-list" },
                        _vm._l(_vm.thisWeekList, function (reminder) {
                          return _c("card-reminder", {
                            key: reminder.id,
                            attrs: { reminder: reminder },
                            on: {
                              reschedule: _vm.onReschedule,
                              snooze: _vm.onSnooze,
                              completed: _vm.onComplete,
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "tw-block xl:tw-hidden" },
                  [
                    _c(
                      "ul",
                      { staticClass: "list-unstyled tw-grid tw-grid-cols-3" },
                      _vm._l(_vm.tabList, function (tab) {
                        return _c(
                          "li",
                          {
                            key: tab.id,
                            staticClass: "tw-py-3 tw-capitalize",
                            class:
                              _vm.activeTab == tab.id
                                ? "tw-border tw-border-solid tw-border-t-0 tw-border-l-0 tw-border-r-0"
                                : "tw-opacity-25",
                            on: {
                              click: function ($event) {
                                _vm.activeTab = tab.id
                              },
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(tab.text) + " "),
                            tab.text == "Past Due"
                              ? [
                                  _c("span", { staticClass: "status-pill" }, [
                                    _vm._v(_vm._s(_vm.pastDueList.length)),
                                  ]),
                                ]
                              : _vm._e(),
                            tab.text == "Today"
                              ? [
                                  _c("span", { staticClass: "status-pill" }, [
                                    _vm._v(_vm._s(_vm.todayList.length)),
                                  ]),
                                ]
                              : _vm._e(),
                            tab.text == "This Week"
                              ? [
                                  _c("span", { staticClass: "status-pill" }, [
                                    _vm._v(_vm._s(_vm.thisWeekList.length)),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                    _vm.activeTab == "pastdue"
                      ? [
                          _c(
                            "div",
                            { staticClass: "card-reminders-list tw-pt-4" },
                            _vm._l(_vm.pastDueList, function (reminder) {
                              return _c("card-reminder", {
                                key: reminder.id,
                                attrs: { reminder: reminder },
                                on: {
                                  reschedule: _vm.onReschedule,
                                  snooze: _vm.onSnooze,
                                  completed: _vm.onComplete,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.activeTab == "today"
                      ? [
                          _c(
                            "div",
                            { staticClass: "card-reminders-list" },
                            _vm._l(_vm.todayList, function (reminder) {
                              return _c("card-reminder", {
                                key: reminder.id,
                                attrs: { reminder: reminder },
                                on: {
                                  reschedule: _vm.onReschedule,
                                  snooze: _vm.onSnooze,
                                  completed: _vm.onComplete,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.activeTab == "thisweek"
                      ? [
                          _c(
                            "div",
                            { staticClass: "card-reminders-list" },
                            _vm._l(_vm.thisWeekList, function (reminder) {
                              return _c("card-reminder", {
                                key: reminder.id,
                                attrs: { reminder: reminder },
                                on: {
                                  reschedule: _vm.onReschedule,
                                  snooze: _vm.onSnooze,
                                  completed: _vm.onComplete,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("table-reminders", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedTab === "ALL",
                expression: "selectedTab === 'ALL'",
              },
            ],
            on: { reopen: _vm.onReopen, completed: _vm.onComplete },
          }),
        ],
        1
      ),
      _vm.page !== "patient-profile" ? _c("modal-set-reminder") : _vm._e(),
      _c("modal-set-reminder", {
        attrs: {
          "modal-type": _vm.isReopen ? "reopen" : "reschedule",
          "selected-reminder": _vm.selectedReminder,
        },
        on: {
          "modal-close": function ($event) {
            _vm.selectedReminder = null
            _vm.isReopen = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }