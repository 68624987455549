var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "mb-0 table-card",
      staticStyle: { border: "0" },
      attrs: { "header-tag": "header", "no-body": "" },
    },
    [
      _c("b-table", {
        ref: "table-reminders",
        staticClass: "patient-table tw-hidden lg:tw-block",
        attrs: {
          responsive: "",
          "select-mode": "single",
          "sticky-header": "calc(100vh - 250px)",
          fields: _vm.fields,
          items: _vm.items,
        },
        nativeOn: {
          scroll: function ($event) {
            return _vm.patientScroll.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(patient)",
            fn: function (data) {
              return [
                _c(
                  "b-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("b-avatar", {
                          attrs: { src: data.item.profileUrl },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "pl-3 tw-flex tw-items-center" },
                      [
                        _c(
                          "p",
                          { staticClass: "mb-0" },
                          [
                            data.item.reminderInfo.subjecttype === "PATIENT"
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "text-secondary tw-cursor-pointer",
                                    attrs: {
                                      to: `/patients/profile/${data.item.userid}`,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "tw-underline" },
                                      [_vm._v(_vm._s(data.item.patient))]
                                    ),
                                  ]
                                )
                              : _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "text-secondary tw-cursor-pointer",
                                    attrs: { to: `/account` },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "tw-underline" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.patient) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                            _c(
                              "small",
                              {
                                staticClass:
                                  "tw-text-[#BDBDBD] tw-block tw-text-sm",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.parseDate(
                                          data.item.birthday,
                                          "MM/dd/yyyy",
                                          true
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(reminders)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "tw-whitespace-pre-line" }, [
                  _vm._v(" " + _vm._s(data.item.reminders) + " "),
                ]),
              ]
            },
          },
          {
            key: "cell(status)",
            fn: function (data) {
              return [
                _c("p", { staticClass: "mb-0" }, [
                  data.item.status === "Open"
                    ? _c("span", { staticClass: "mr-3 dot dot-yellow" })
                    : data.item.status === "Completed"
                    ? _c("span", { staticClass: "mr-3 dot dot-green" })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(data.item.status))]),
                ]),
              ]
            },
          },
          {
            key: "cell(action)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    data.item.status === "Open"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "tw-capitalize tw-tracking-normal",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "completed",
                                  data.item.reminderInfo
                                )
                              },
                            },
                          },
                          [_vm._v(" Complete ")]
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "tw-capitalize tw-tracking-normal",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "reopen",
                                  data.item.reminderInfo
                                )
                              },
                            },
                          },
                          [_vm._v(" Re-open ")]
                        ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap lg:tw-hidden",
          style: { maxHeight: "calc(100vh - 240px)", overflowY: "auto" },
          on: { scroll: _vm.patientScroll },
        },
        _vm._l(_vm.items, function (card, i) {
          return _c(
            "div",
            {
              key: card + i,
              staticClass: "tw-bg-white patient-table-card tw-shadow-md",
            },
            [
              _c("div", { staticClass: "tw-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-w-[40px] tw-h-[40px] tw-max-w-[40px] tw-overflow-hidden tw-rounded-full tw-mr-2 card-table-img tw-flex",
                  },
                  [
                    _c("img", {
                      staticClass: "tw-object-cover",
                      attrs: {
                        src: card.profileUrl,
                        width: "40",
                        height: "40",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tw-flex-1",
                    staticStyle: { "word-break": "break-all" },
                  },
                  [
                    card.reminderInfo.subjecttype === "PATIENT"
                      ? _c(
                          "router-link",
                          {
                            staticClass: "text-secondary tw-cursor-pointer",
                            attrs: { to: `/patients/profile/${card.userid}` },
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(card.patient) + " "),
                            ]),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass: "text-secondary tw-cursor-pointer",
                            attrs: { to: `/account` },
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(card.patient) + " "),
                            ]),
                          ]
                        ),
                    _c("span", { staticClass: "tw-block" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.parseDate(card.birthday, "MM/dd/yyyy", true)
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "tw-pl-4 tw-w-1/2 tw-text-right" }, [
                  _c("span", { staticClass: "tw-block" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseDate(card.dateCreated, "MM/dd/yyyy", true)
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", { staticClass: "mb-0" }, [
                    card.status === "Open"
                      ? _c("span", { staticClass: "mr-3 dot dot-yellow" })
                      : card.status === "Completed"
                      ? _c("span", { staticClass: "mr-3 dot dot-green" })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(card.status))]),
                  ]),
                ]),
              ]),
              _c("p", { staticClass: "tw-mt-2 tw-mb-2" }, [
                _vm._v(" " + _vm._s(card.reminders) + " "),
              ]),
              _c(
                "div",
                { staticClass: "text-center pt-4" },
                [
                  card.status === "Open"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "tw-capitalize tw-tracking-normal",
                          attrs: { variant: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("completed", card.reminderInfo)
                            },
                          },
                        },
                        [_vm._v(" Complete ")]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "tw-capitalize tw-tracking-normal",
                          attrs: { variant: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("reopen", card.reminderInfo)
                            },
                          },
                        },
                        [_vm._v(" Re-open ")]
                      ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }