<template>
  <div
    :class="[
      isClicked && !currentDoctor.id ? 'focused' : '',
      !currentDoctor.id ? 'card-dashboard-item' : '',
    ]"
    class="
      tw-relative tw-rounded-md tw-pt-2 tw-mb-4 tw-shadow-md tw-transition-all
    "
    @click="isClicked = !isClicked"
    v-click-outside="onClickOutside"
  >
    <span
      v-if="reminder.snoozeuntil"
      class="
        tw-block
        tw-w-full
        tw-text-center
        tw-px-4
        tw-py-1
        tw-text-[11px]
        tw-text-white
        tw-bg-muted
      "
    >
      Snoozed for {{ distanceToNow(reminder.snoozeuntil) }}
    </span>
    <div class="card-dashboard-content tw-p-4">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-flex tw-items-center">
          <b-avatar :src="reminder.profileUrl" class="tw-flex-none"></b-avatar>
          <p
            class="mb-0 tw-ml-2 text-black d-inline-block"
            v-if="currentDoctor.id"
          >
            {{ reminder.name }}
          </p>
          <router-link
            v-else
            class="mb-0 tw-ml-2 tw-text-base tw-underline"
            :to="
              reminder.subjectid
                ? `/patients/profile/${reminder.subjectid}`
                : `/account`
            "
          >
            {{ reminder.name }}
          </router-link>
        </div>
        <div class="tw-text-muted">
          {{ parseDate(reminder.reminderdatetimeutc, "MM/dd/yyyy") }}
        </div>
      </div>
      <div class="tw-col-span-2 tw-mt-3 tw-whitespace-pre-line">
        {{ reminder.reminder }}
      </div>
    </div>
    <div
      class="
        btn-holder
        tw-flex tw-h-[40px] tw-w-full tw-rounded-b-md tw-transition-all
      "
      v-if="!currentDoctor.id"
    >
      <button
        class="
          tw-border-0
          tw-text-center
          tw-w-full
          tw-h-[40px]
          tw-text-white
          tw-bg-[#828282]
        "
        @click="$emit('snooze', reminder)"
      >
        Snooze
      </button>
      <button
        class="
          tw-border-0
          tw-text-center
          tw-w-full
          tw-h-[40px]
          tw-text-white
          tw-bg-[#FF8D40]
        "
        @click="$emit('reschedule', reminder)"
      >
        Reschedule
      </button>
      <button
        class="
          tw-border-0
          tw-text-center
          tw-w-full
          tw-h-[40px]
          tw-text-white
          tw-bg-primary
        "
        @click="$emit('completed', reminder)"
      >
        Complete
      </button>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import { format, fromUnixTime, formatDistanceToNowStrict } from "date-fns";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    reminder: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isClicked: false,
    };
  },
  computed: {
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  methods: {
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    onClickOutside() {
      this.isClicked = false;
    },
    distanceToNow(date) {
      return formatDistanceToNowStrict(fromUnixTime(date));
    },
  },
};
</script>

<style lang="scss" scoped>
.card-dashboard-content {
  background-color: $white;
}

.card-dashboard-item {
  padding-bottom: 0;

  .btn-holder {
    position: absolute;
    bottom: -40px;
    opacity: 0;
  }
}

.card-dashboard-item:hover,
.card-dashboard-item.focused {
  padding-bottom: 40px;

  .btn-holder {
    bottom: 0;
    opacity: 1;
  }
}
</style>
