var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass:
        "tw-relative tw-rounded-md tw-pt-2 tw-mb-4 tw-shadow-md tw-transition-all",
      class: [
        _vm.isClicked && !_vm.currentDoctor.id ? "focused" : "",
        !_vm.currentDoctor.id ? "card-dashboard-item" : "",
      ],
      on: {
        click: function ($event) {
          _vm.isClicked = !_vm.isClicked
        },
      },
    },
    [
      _vm.reminder.snoozeuntil
        ? _c(
            "span",
            {
              staticClass:
                "tw-block tw-w-full tw-text-center tw-px-4 tw-py-1 tw-text-[11px] tw-text-white tw-bg-muted",
            },
            [
              _vm._v(
                " Snoozed for " +
                  _vm._s(_vm.distanceToNow(_vm.reminder.snoozeuntil)) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "card-dashboard-content tw-p-4" }, [
        _c(
          "div",
          { staticClass: "tw-flex tw-items-center tw-justify-between" },
          [
            _c(
              "div",
              { staticClass: "tw-flex tw-items-center" },
              [
                _c("b-avatar", {
                  staticClass: "tw-flex-none",
                  attrs: { src: _vm.reminder.profileUrl },
                }),
                _vm.currentDoctor.id
                  ? _c(
                      "p",
                      { staticClass: "mb-0 tw-ml-2 text-black d-inline-block" },
                      [_vm._v(" " + _vm._s(_vm.reminder.name) + " ")]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass: "mb-0 tw-ml-2 tw-text-base tw-underline",
                        attrs: {
                          to: _vm.reminder.subjectid
                            ? `/patients/profile/${_vm.reminder.subjectid}`
                            : `/account`,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.reminder.name) + " ")]
                    ),
              ],
              1
            ),
            _c("div", { staticClass: "tw-text-muted" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.parseDate(
                      _vm.reminder.reminderdatetimeutc,
                      "MM/dd/yyyy"
                    )
                  ) +
                  " "
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "tw-col-span-2 tw-mt-3 tw-whitespace-pre-line" },
          [_vm._v(" " + _vm._s(_vm.reminder.reminder) + " ")]
        ),
      ]),
      !_vm.currentDoctor.id
        ? _c(
            "div",
            {
              staticClass:
                "btn-holder tw-flex tw-h-[40px] tw-w-full tw-rounded-b-md tw-transition-all",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "tw-border-0 tw-text-center tw-w-full tw-h-[40px] tw-text-white tw-bg-[#828282]",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("snooze", _vm.reminder)
                    },
                  },
                },
                [_vm._v(" Snooze ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "tw-border-0 tw-text-center tw-w-full tw-h-[40px] tw-text-white tw-bg-[#FF8D40]",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("reschedule", _vm.reminder)
                    },
                  },
                },
                [_vm._v(" Reschedule ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "tw-border-0 tw-text-center tw-w-full tw-h-[40px] tw-text-white tw-bg-primary",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("completed", _vm.reminder)
                    },
                  },
                },
                [_vm._v(" Complete ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }