<template>
  <b-card header-tag="header" no-body class="mb-0 table-card" style="border: 0">
    <b-table
      ref="table-reminders"
      responsive
      select-mode="single"
      sticky-header="calc(100vh - 250px)"
      :fields="fields"
      :items="items"
      @scroll.native="patientScroll"
      class="patient-table tw-hidden lg:tw-block"
    >
      <template v-slot:cell(patient)="data">
        <b-row no-gutters>
          <b-col cols="auto">
            <b-avatar :src="data.item.profileUrl"></b-avatar>
          </b-col>
          <b-col class="pl-3 tw-flex tw-items-center">
            <p class="mb-0">
              <router-link
                v-if="data.item.reminderInfo.subjecttype === 'PATIENT'"
                :to="`/patients/profile/${data.item.userid}`"
                class="text-secondary tw-cursor-pointer"
              >
                <span class="tw-underline">{{ data.item.patient }}</span>
              </router-link>
              <router-link
                v-else
                :to="`/account`"
                class="text-secondary tw-cursor-pointer"
              >
                <span class="tw-underline">
                  {{ data.item.patient }}
                </span>
              </router-link>
              <small class="tw-text-[#BDBDBD] tw-block tw-text-sm">
                <span>
                  {{ parseDate(data.item.birthday, "MM/dd/yyyy", true) }}
                </span>
              </small>
            </p>
          </b-col>
        </b-row>
      </template>
      <template v-slot:cell(reminders)="data">
        <div class="tw-whitespace-pre-line">
          {{ data.item.reminders }}
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <p class="mb-0">
          <span
            class="mr-3 dot dot-yellow"
            v-if="data.item.status === 'Open'"
          ></span>
          <span
            class="mr-3 dot dot-green"
            v-else-if="data.item.status === 'Completed'"
          ></span>
          <span>{{ data.item.status }}</span>
        </p>
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-right">
          <b-button
            v-if="data.item.status === 'Open'"
            variant="link"
            class="tw-capitalize tw-tracking-normal"
            @click="$emit('completed', data.item.reminderInfo)"
          >
            Complete
          </b-button>
          <b-button
            v-else
            variant="link"
            class="tw-capitalize tw-tracking-normal"
            @click="$emit('reopen', data.item.reminderInfo)"
          >
            Re-open
          </b-button>
        </div>
      </template>
    </b-table>

    <div
      class="flex flex-wrap lg:tw-hidden"
      :style="{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }"
      @scroll="patientScroll"
    >
      <div
        class="tw-bg-white patient-table-card tw-shadow-md"
        v-for="(card, i) in items"
        :key="card + i"
      >
        <div class="tw-flex">
          <div
            class="
              tw-w-[40px]
              tw-h-[40px]
              tw-max-w-[40px]
              tw-overflow-hidden
              tw-rounded-full
              tw-mr-2
              card-table-img
              tw-flex
            "
          >
            <img
              :src="card.profileUrl"
              width="40"
              height="40"
              alt=""
              class="tw-object-cover"
            />
          </div>
          <div class="tw-flex-1" style="word-break: break-all">
            <router-link
              v-if="card.reminderInfo.subjecttype === 'PATIENT'"
              :to="`/patients/profile/${card.userid}`"
              class="text-secondary tw-cursor-pointer"
            >
              <span>
                {{ card.patient }}
              </span>
            </router-link>
            <router-link
              v-else
              :to="`/account`"
              class="text-secondary tw-cursor-pointer"
            >
              <span>
                {{ card.patient }}
              </span>
            </router-link>
            <span class="tw-block">
              {{ parseDate(card.birthday, "MM/dd/yyyy", true) }}
            </span>
          </div>
          <div class="tw-pl-4 tw-w-1/2 tw-text-right">
            <span class="tw-block">
              {{ parseDate(card.dateCreated, "MM/dd/yyyy", true) }}
            </span>
            <p class="mb-0">
              <span
                class="mr-3 dot dot-yellow"
                v-if="card.status === 'Open'"
              ></span>
              <span
                class="mr-3 dot dot-green"
                v-else-if="card.status === 'Completed'"
              ></span>
              <span>{{ card.status }}</span>
            </p>
          </div>
        </div>

        <p class="tw-mt-2 tw-mb-2">
          {{ card.reminders }}
        </p>

        <div class="text-center pt-4">
          <b-button
            v-if="card.status === 'Open'"
            variant="link"
            class="tw-capitalize tw-tracking-normal"
            @click="$emit('completed', card.reminderInfo)"
          >
            Complete
          </b-button>
          <b-button
            v-else
            variant="link"
            class="tw-capitalize tw-tracking-normal"
            @click="$emit('reopen', card.reminderInfo)"
          >
            Re-open
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { PatientService } from "@/services/patient.service";
import { mapGetters } from "vuex";

import { format, fromUnixTime } from "date-fns";

export default {
  props: {
    title: {
      type: String,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {
      type: String,
      default: "400px",
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      patientid: "",
      loading: true,
      alert: {},

      nextPage: "",
      fields: [
        { key: "patient", sortable: true, thClass: "position-sticky" },
        {
          key: "reminders",
          sortable: false,
          thStyle: "width: 30%",
        },
        { key: "status", sortable: true, thClass: "position-sticky" },
        { key: "dateCreated", sortable: false },
        { key: "dateCompleted", sortable: false },
        { key: "action", sortable: false, thClass: "text-right" },
      ],
    };
  },
  async created() {
    if (this.$route.name === "patient-profile") {
      this.fields.shift();
    }
    if (this.currentDoctor.id) {
      this.fields.pop();
    }
    this.rows = this.items.length;
    await this.getReminders();
  },
  computed: {
    items() {
      return this.$store.state.reminder.reminderList.map((item) => ({
        patient: item.name,
        birthday: item.birthday,
        reminders: item.reminder,
        status: item.reminderstatus === "COMPLETED" ? "Completed" : "Open",
        dateCreated: this.parseDate(item.timestampcreated, "MM/dd/yyyy"),
        dateCompleted: item.timestampcompleted
          ? this.parseDate(item.timestampcompleted, "MM/dd/yyyy")
          : "-",
        profileUrl: item.profileUrl,
        userid: item.subjecttype === "PATIENT" ? item.subjectid : item.userid,
        reminderInfo: item,
      }));
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  methods: {
    parseDate(date, stringFormat, isDate) {
      if (date) {
        return format(
          isDate ? new Date(date) : fromUnixTime(date),
          stringFormat
        );
      }
    },
    async getReminders() {
      if (this.$route.name === "patient-profile") {
        await this.$store.dispatch("reminder/getPatientReminderList", {
          patientid: localStorage.getItem("patientId"),
        });
      } else {
        await this.$store.dispatch("reminder/getReminderList");
      }
      // this.nextPage = res.nextpage;
      // this.isBusy = false;
      // return this.items;
    },
    patientScroll() {},
  },
};
</script>

<style scoped>
/deep/.pagination {
  margin-bottom: 0;
}

/deep/.page-item.active {
  display: none;
}

/deep/.page-link[role="menuitemradio"] {
  display: none;
}

/deep/.page-item.active .page {
  display: none;
}

/deep/.table thead th,
/deep/.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: rgb(243, 247, 248);
  border-bottom: 1px solid #1a6a72;
}

/deep/.table.b-table > thead > tr > .table-b-table-default {
  color: #828282;
  text-transform: capitalize;
  font-weight: normal;
  letter-spacing: 0.75px;
}

/deep/.table thead th:focus {
  user-select: none;
}
</style>

<style lang="scss">
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: $primary;
  color: $white;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  border-top: 0;
}

.table thead th {
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.card.table-card {
  border: 0;
  background-color: rgb(243, 247, 248);
}

.table thead th {
  border: 0;
}

.table tbody td {
  border-bottom: 0;
  border-top: 1px solid rgba(26, 106, 114, 0.2);
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-green {
  background-color: $green;
}

.dot-red {
  background-color: $red;
}

.dot-yellow {
  background-color: #f5d83e;
}
</style>
