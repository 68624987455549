<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" class="mt-5 mb-5">
        <b-col cols="auto" md="2">
          <h1
            class="mb-0 text-primary"
            style="font-size: 22px; font-weight: 500"
          >
            Reminders
          </h1>
        </b-col>
        <b-col
          cols="12"
          md="10"
          class="tw-items-center tw-flex"
          :class="
            ['patient-profile', 'treatments'].includes(page)
              ? 'tw-justify-end'
              : 'tw-justify-between'
          "
        >
          <div
            class="tw-pt-6 md:tw-pt-0 tw-flex-1 tw-flex"
            v-if="!['patient-profile', 'treatments'].includes(page)"
          >
            <div
              @click="selectedTab = 'OVERVIEW'"
              class="text-muted mr-4"
              style="cursor: pointer"
            >
              <span :class="selectedTab === 'OVERVIEW' ? 'text-primary' : ''">
                Overview
              </span>
              <span
                class="status-pill"
                :class="selectedTab === 'OVERVIEW' ? 'pill-dark-cyan' : ''"
              >
                {{
                  pastDueList.length + todayList.length + thisWeekList.length
                }}
              </span>
            </div>
            <div
              @click="selectedTab = 'ALL'"
              class="text-muted mr-4"
              style="cursor: pointer"
            >
              <span :class="selectedTab === 'ALL' ? 'text-primary' : ''">
                All
              </span>
              <span
                class="status-pill"
                :class="selectedTab === 'ALL' ? 'pill-dark-cyan' : ''"
              >
                {{ reminderList.length }}
              </span>
            </div>
          </div>
          <div
            v-if="!currentDoctor.id"
            class="
              tw-absolute tw-top-[calc(-100%/2-10px)] tw-right-0
              md:tw-relative md:tw-top-auto
            "
          >
            <b-button
              pill
              block
              :variant="
                page === 'patient-profile' ? 'outline-primary' : 'primary'
              "
              style="width: 190px"
              @click="$bvModal.show('setReminder')"
            >
              Set Reminder
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-col>
      <div
        v-if="
          selectedTab === 'OVERVIEW' &&
          !['patient-profile', 'treatments'].includes(page)
        "
      >
        <ul
          class="
            list-unstyled
            xl:tw-grid-cols-3
            tw-space-x-4 tw-hidden
            xl:tw-grid
          "
        >
          <li>
            <div
              class="
                tw-py-3
                tw-capitalize
                tw-border
                tw-border-solid
                tw-border-primary
                tw-border-t-0
                tw-border-l-0
                tw-border-r-0
                tw-flex
                tw-justify-between
                tw-relative
              "
            >
              Past Due
              <span class="status-pill">{{ pastDueList.length }} </span>
            </div>
            <div class="card-reminders-list">
              <card-reminder
                v-for="reminder in pastDueList"
                :key="reminder.id"
                :reminder="reminder"
                @reschedule="onReschedule"
                @snooze="onSnooze"
                @completed="onComplete"
              />
            </div>
          </li>

          <li>
            <div
              class="
                tw-py-3
                tw-capitalize
                tw-border
                tw-border-solid
                tw-border-primary
                tw-border-t-0
                tw-border-l-0
                tw-border-r-0
                tw-flex
                tw-justify-between
                tw-relative
              "
            >
              Today
              <span class="status-pill">{{ todayList.length }}</span>
            </div>
            <div class="card-reminders-list">
              <card-reminder
                v-for="reminder in todayList"
                :key="reminder.id"
                :reminder="reminder"
                @reschedule="onReschedule"
                @snooze="onSnooze"
                @completed="onComplete"
              />
            </div>
          </li>

          <li>
            <div
              class="
                tw-py-3
                tw-capitalize
                tw-border
                tw-border-solid
                tw-border-primary
                tw-border-t-0
                tw-border-l-0
                tw-border-r-0
                tw-flex
                tw-justify-between
                tw-relative
              "
            >
              This Week
              <span class="status-pill">{{ thisWeekList.length }}</span>
            </div>
            <div class="card-reminders-list">
              <card-reminder
                v-for="reminder in thisWeekList"
                :key="reminder.id"
                :reminder="reminder"
                @reschedule="onReschedule"
                @snooze="onSnooze"
                @completed="onComplete"
              />
            </div>
          </li>
        </ul>

        <div class="tw-block xl:tw-hidden">
          <ul class="list-unstyled tw-grid tw-grid-cols-3">
            <li
              v-for="tab in tabList"
              :key="tab.id"
              :class="
                activeTab == tab.id
                  ? 'tw-border tw-border-solid tw-border-t-0 tw-border-l-0 tw-border-r-0'
                  : 'tw-opacity-25'
              "
              class="tw-py-3 tw-capitalize"
              @click="activeTab = tab.id"
            >
              {{ tab.text }}
              <template v-if="tab.text == 'Past Due'">
                <span class="status-pill">{{ pastDueList.length }}</span>
              </template>
              <template v-if="tab.text == 'Today'">
                <span class="status-pill">{{ todayList.length }}</span>
              </template>
              <template v-if="tab.text == 'This Week'">
                <span class="status-pill">{{ thisWeekList.length }}</span>
              </template>
            </li>
          </ul>

          <template v-if="activeTab == 'pastdue'">
            <div class="card-reminders-list tw-pt-4">
              <card-reminder
                v-for="reminder in pastDueList"
                :key="reminder.id"
                :reminder="reminder"
                @reschedule="onReschedule"
                @snooze="onSnooze"
                @completed="onComplete"
              />
            </div>
          </template>

          <template v-if="activeTab == 'today'">
            <div class="card-reminders-list">
              <card-reminder
                v-for="reminder in todayList"
                :key="reminder.id"
                :reminder="reminder"
                @reschedule="onReschedule"
                @snooze="onSnooze"
                @completed="onComplete"
              />
            </div>
          </template>

          <template v-if="activeTab == 'thisweek'">
            <div class="card-reminders-list">
              <card-reminder
                v-for="reminder in thisWeekList"
                :key="reminder.id"
                :reminder="reminder"
                @reschedule="onReschedule"
                @snooze="onSnooze"
                @completed="onComplete"
              />
            </div>
          </template>
        </div>
      </div>

      <table-reminders
        v-show="selectedTab === 'ALL'"
        @reopen="onReopen"
        @completed="onComplete"
      ></table-reminders>
    </b-col>

    <!-- Set Reminder Modal -->
    <modal-set-reminder v-if="page !== 'patient-profile'"></modal-set-reminder>

    <!-- Reschedule Reminder Modal -->
    <modal-set-reminder
      :modal-type="isReopen ? 'reopen' : 'reschedule'"
      :selected-reminder="selectedReminder"
      @modal-close="
        selectedReminder = null;
        isReopen = false;
      "
    ></modal-set-reminder>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import ModalSetReminder from "@/components/reminder/ModalSetReminder.vue";
import CardReminder from "@/components/reminder/CardReminder.vue";
import TableReminders from "@/components/reminder/TableReminders.vue";
export default {
  components: {
    ModalSetReminder,
    CardReminder,
    TableReminders,
  },
  props: {
    page: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedTab: "OVERVIEW",
      selectedReminder: null,
      isReopen: false,
      activeTab: "pastdue",
      tabList: [
        {
          id: "pastdue",
          text: "Past Due",
        },
        {
          id: "today",
          text: "Today",
        },
        {
          id: "thisweek",
          text: "This Week",
        },
      ],
    };
  },
  async created() {
    window.analytics.page();
    this.fetchRemindersList();

    if (["patient-profile", "treatments"].includes(this.$route.name)) {
      this.selectedTab = "ALL";
    }
  },
  computed: {
    reminders() {
      return this.$store.state.reminder.reminderList;
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
    ...mapState("reminder", [
      "pastDueList",
      "todayList",
      "thisWeekList",
      "reminderList",
    ]),
  },
  methods: {
    async fetchRemindersList() {
      if (!["patient-profile", "treatments"].includes(this.$route.name)) {
        this.$store.dispatch("reminder/getPastDueReminderList");
        this.$store.dispatch("reminder/getTodayReminderList");
        this.$store.dispatch("reminder/getThisWeekReminderList");
      }
    },
    onReschedule(reminder) {
      this.selectedReminder = reminder;
      this.$bvModal.show("rescheduleReminder");
    },
    onReopen(reminder) {
      this.isReopen = true;
      this.selectedReminder = reminder;
      this.$nextTick(() => {
        this.$bvModal.show("reopenReminder");
      });
    },
    async onSnooze(reminder) {
      await this.$store.dispatch("reminder/snoozeReminder", reminder.id);
    },
    async onComplete(reminder) {
      await this.$store.dispatch("reminder/completeReminder", {
        reminderid: reminder.id,
        routename: this.$route.name,
        ...(this.page === "patient-profile" && {
          patientid: localStorage.getItem("patientId"),
        }),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-pill {
  background: #f2f2f2;
  border-radius: 999px;
  padding: 4px 8px;
  margin-left: 0.25rem;
  font-size: 0.75rem;
}

.pill-purple {
  background: #a783ff;
  color: #edffe1;
}

.pill-monarch {
  background: #ff8d40;
  color: #ffecca;
}

.pill-dark-cyan {
  background: $primary;
  color: #ccffff;
}

.text-purple {
  color: #a783ff;
}

.text-monarch {
  color: #ff8d40;
}

.text-dark-cyan {
  color: $primary;
}

.card.table-card {
  border: 0;
  background-color: rgb(250 251 252);
}

// .reminder-section {
//   background: #f3f7f8;
// }

// .darkmode {
//   .reminder-section {
//     background: #000000;
//     border: none;
//   }
// }

.card-reminders-list {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  @include media-breakpoint-up(md) {
    height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
}
</style>
